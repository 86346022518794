<template>
  <v-chip
    class="sowing-item-status-chip"
    label
    :color="backgroundColor"
    :style="{ border: chipBorder }"
  >
    <font-awesome-icon
      v-if="shouldShowIcon"
      class="chip__icon"
      :icon="icon"
      :style="{ color: iconAndTextColor }"
    />
    <p class="chip__text" :style="{ color: iconAndTextColor }">
      {{ $t(`SowingsList.status_chip.${status}`) }}
    </p>
  </v-chip>
</template>

<script>
export default {
  name: 'SowingItemStatusChip',

  props: {
    status: {
      required: true,
      type: String,
    },
  },

  computed: {
    backgroundColor() {
      const statusBackgroundColors = {
        harvested: '#D8F2DC',
        ready_to_harvest: '#FFFFFF',
        ongoing: '#FFFFFF',
        scheduled: '#E6E9E6',
      }
      return statusBackgroundColors[this.status]
    },
    chipBorder() {
      const statusBorders = {
        ready_to_harvest: '1px solid #E6E9E6',
        ongoing: '1px solid #E6E9E6',
      }
      return statusBorders[this.status]
    },
    icon() {
      const statusIcons = {
        harvested: 'check',
        scheduled: 'clock',
      }
      return statusIcons[this.status]
    },
    iconAndTextColor() {
      const statusIconTextColors = {
        harvested: '#39AF49',
        ready_to_harvest: '#1A2B46',
        ongoing: '#1A2B46',
        scheduled: '#5B6459',
      }
      return statusIconTextColors[this.status]
    },
    shouldShowIcon() {
      const statusWithIcon = ['harvested', 'scheduled']
      return statusWithIcon.includes(this.status)
    },
  },
}
</script>

<style lang="scss" scoped>
.sowing-item-status-chip {
  max-height: 24px;
  max-width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;

  .chip__icon {
    margin-right: 8px;
  }
  .chip__text {
    margin-top: 1px;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    @include d(m) {
      font-size: 12px;
    }
  }
}
::v-deep .v-chip__content {
  max-height: 16px;
}
</style>
